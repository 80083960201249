.middle-Content-Student {
    .topstudent-section {
      margin-top: 54px;
      justify-content: space-between;
      align-items: flex-start;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
      .left-student {
        gap: 21px;
        .girlImage {
          width: 128px;
          height: 128px;
          border-radius:50%;
          background-color: $base_color;
        }
        .info {
          .heading {
            span {
              font-weight: 500;
              font-size: 51.8349px;
              line-height: 58px;
              /* identical to box height, or 112% */
  
              color: #3b3b3b;
            }
            img {
              width: 37px;
              height: 37px;
              margin-left: 22px;
            }
          }
          .date,
          .generalInfo {
            font-weight: 500;
            font-size: 21.2567px;
  
            line-height: 42px;
            /* identical to box height, or 196% */
  
            color: #7a7a7a;
            @media screen and (max-width: 968px) {
              font-size: 18px;
            }
          }
          .generalInfo {
            gap: 21px;
            width: 100%;
            @media screen and (max-width: 1200px) {
              flex-direction: column;
              align-items: flex-start;
              gap: 0px;
            }
            .left,
            .right {
              display: flex;
              align-items: center;
              gap: 8px;
              // flex-flow: wrap;
              .imgStudent-section {
                width: 26px;
                height: 26px;
              }
            }
          }
        }
      }
      .right-student {
      }
    }
    .progress_game {
      gap: 41px;
      @media screen and (max-width: 968px) {
        flex-direction: column;
      }
      .lesson_progress {
        margin-top: 53px;
        width: 50%;
        @media screen and (max-width: 968px) {
          width: 100%;
        }
  
        .graph {
          margin-top: 34px;
          background: #ffffff;
          border: 0.928571px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
          border-radius: 18.5714px;
          overflow: hidden;
        }
  
        .heading {
          font-weight: 600;
          font-size: 24px;
          line-height: 20px;
          /* identical to box height, or 53% */
  
          color: #000000;
        }
      }
    }
  }
  
  // lesson details
  
  .lessonDetails {
    margin-top: 57px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    .accordionTop {
      width: 100%;
      justify-content: space-between;
      .lesson-No {
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        /* or 133% */
  
        display: flex;
        align-items: center;
  
        color: #7a7a7a;
      }
      img {
        width: 33px;
        height: 33px;
      }
      .progress-outer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5.5px;
        .progress {
          max-width: 400px;
          min-width: 200px;
          background-color: #cbe7d9;
          height: 13.32px;
          border-radius: 50px;
          @media screen and (max-width: 968px) {
            min-width: 150px;
          }
  
          .progressBar {
            // background-color: red;
            height: 13.32px;
            // opacity: 0.18;
            border-radius: 50px;
          }
        }
        .percentageCompleted {
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
  
          color: #17191c;
        }
      }
    }
}

// dialog box
.image-section{
    justify-content: center;
    align-items: center;
    #image{
        display: none;
    }
}

.top-header-dialog{
    position: relative;
    img{
        position: absolute;
        right: 0px;
        top: 0px;
    }
}
.image-change-label{
    font-weight: 400;
font-size: 19px;
line-height: 12px;
/* identical to box height, or 62% */

color: #7A7777;
cursor: pointer;
}


// bottom section of student sections

.bottom-content{
  margin-top: 39px;
  gap: 65px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .box-content{
    border: 0.928571px solid rgba(32, 32, 32, 0.2);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 8px;
padding: 16px;
padding-bottom: 33px;

.heading{
  // gap: 88px;
  justify-content: space-between;
  font-weight: 500;
  font-size: 28.7857px;
  line-height: 35px;
  color: $base_color;
  .left{
    font-weight: 500;
    font-size: 28.7857px;
    line-height: 35px;
    height: 40px;
  }
  .right{
    font-weight: 500;
font-size: 21px;
line-height: 25px;

color: #000000;

  }
}

.bottom{
  margin-top: 70px;
  .bar{
    // width: 306.71px;
height: 15.38px;

background: rgba(239, 91, 44, 0.5);
border-radius: 18.4632px;
.progress{
  background: $base_color;
  width: 50%;
  height: 100%;
border-radius: 18.4632px;


}
  }

}
  }
}

.progress-bottom{
  .progress-heading{
    font-weight: 600;
font-size: 31px;
line-height: 38px;

color: #17191C;
margin-top: 45px;
margin-bottom: 34px;

  }
  .statistics{
    padding: 31px 20px;
    background: rgba(230, 230, 230, 0.5);
    border-radius: 9px;
    display: flex;
    flex-flow: row wrap;
    gap: 23px;
    .outer{
      background: #FFFFFF;
      border-radius: 1.77224px;     
       width: 254.83px;
       height: 79.31px;


      &::before{
        content: "";
        width: 11.39px;
height: 100%;
display: inline-block;


background: $base_color;
border-radius: 1.77224px 0px 0px 1.77224px
      }
      .inner{
        padding: 5px;
        .top{
          font-weight: 500;
font-size: 15.9501px;
line-height: 19px;

letter-spacing: 0.0290002px;

color: #000000;

        }
        .bottom{
          // align-items: left;
          justify-content: left;
          align-items: center;
          gap: 11px;
          margin-top: 7px;
          .stat{
            font-weight: 400;
font-size: 26.5836px;
line-height: 33px;

letter-spacing: 0.0290002px;

color: #000000;
          }
        }
      }
    }
  }
  
}

.imageStudent{
  width:150px;
  height:150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}

button:disabled{
  background: #dddddd;
  color: rgb(46, 42, 42);
 
}