.studentScore{
    .scores{
.MuiAccordion-root{
    background-color: transparent;
    border: none;
    outline: 0ch;
    margin: 0px;
    &::before{
        height: 0px;
    }
    .MuiAccordionSummary-root{
        border: 0px !important;
        outline: none;
        box-shadow: none;
        background-color: transparent;


        .MuiAccordionSummary-content{
            button{
                background-color: transparent;
                padding:0;

            }
            .leaderBoardCardMain{
                height: 86px;
                border-radius: 7px;
                width: 100%;
                display: flex;
                align-items: center;
                span{
                    font-family: $righteous;
            font-style: normal;
            font-weight: 400;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
                }
                .sn{
            font-size: 48px;
            line-height: 60px;
                }
                .name{
                    font-size: 36px;
            line-height: 45px;
                }
                .speciality{
                    font-size: 14px;
            line-height: 17px;
                }
                .cv{
                    font-size: 18px;
            line-height: 88.67%;
                }
                .cvPrice{
                    font-size: 36px;
            line-height: 88.67%;
                }
                position: relative;
                .avatarImg{
                    position: absolute;
                    right: 0;
                    top: -50px;
                    width: 140px;
                    height:295px;
                    // margin-right: 20px;
                }
                .starIcon{
                    width: 10px;
                }
            }
        }

        .accordion-button::after{
background-image: none;
        }
        box-shadow: none;

        .accordion-button:not(.collapsed) {
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.MuiCollapse-root{
    padding-left: 0;
    padding-top: 1rem;
    border: none;
    outline: 0ch; 

    .leaderboardAccordionBody{
        margin-top: -50px;
        // background: linear-gradient(90deg, rgba(239, 91, 44, 0.5) 0%, rgba(239, 91, 44, 0.5) 20.46%, rgba(239, 91, 44, 0) 193.46%);
border-bottom-left-radius: 7px;
border-bottom-right-radius: 7px;

        .card{
            margin-bottom: 10px;
            width: 40%;
            height: 75px;
            background: #FFFFFF;
             border-radius: 2px;
             border-left: 10px $base_color solid;
             display: flex;
             flex-direction: column;
             justify-content: center;
             padding: 10px;
             margin: 10px;
             .title{
                 font-family: $righteous;
 font-style: normal;
 font-weight: 500;
 font-size: 13px;
 line-height: 16px;
 letter-spacing: 0.0327273px;
 color: #000000;
             }
             .secDiv{
                 display: flex;
                 // justify-content: space-between;
                 span{
                     font-family: $righteous;
 font-style: normal;
 font-weight: 400;
 font-size: 24px;
 line-height: 30px;
                 }
             }
        }
    }
}
}
}