.contentChat{
    display: flex;
    // align-items: center;
    flex-direction: row;
    gap: 63px;
   
    padding-top: 19px;
.rightContent-chat{
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    .chatGroup-box{
        background: rgba(239, 91, 44, 0.04);
border-radius: 18px;
        padding-top: 15px;
        padding-right: 21px;
        padding-bottom: 21px;
        // width:245px;
        padding-left: 16px;
        @media screen and (max-width: 968px) {
            font-size: 21px;
              }
        .top{
            display: flex;
            align-items: center;
            // justify-content: space-between;
            gap: 10px;
            justify-content: space-between;
            margin-bottom: 15px;
            .header{
                font-weight: 500;
font-size: 18px;
// line-height: 13px;
/* identical to box height, or 71% */



color: #3B3B3B;
                .greenDot{
                    width:8px;
                    height:8px;
                    display: inline-block;
                    background: #3AD740;
                    margin-left:6px;
                    border-radius: 50%;
                    


                }
            }
            .time{
                font-weight: 500;
font-size: 16px;
line-height: 13px;
/* identical to box height, or 80% */


color: #AEAEAE;
            }
        }
        .reply-msg{
            font-weight: 500;
font-size: 14px;
line-height: 13px;
/* identical to box height, or 91% */
border:none;

padding: 8px;
color: #FFFFFF;
background: $base_color;
border-radius: 7px;
        }
        .assigned-teacher{
            font-weight: 500;
            margin-top: 9px;
font-size: 14px;
line-height: 13px;
/* identical to box height, or 91% */


color: #3B3B3B;
        }
    }

}
.middleContent-chat{
 
    // border: 1px solid red;
    width: 70%;
    position: relative;

    // display: flex;
 .top{
    //  display: flex;
    //  gap: 100px;

     .date{
         display: flex;
         align-items: center;
         justify-content: center;
         font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-top: 50px;
/* or 80% */



color: #AEAEAE;

     }
     .imageInside{
        position:absolute;

right: 0px;
top: 20px;

@media screen and (max-width: 968px) {
    top: 0px;
    // left: 50%;
    // transform: translateX(-50%);
      }
.img_inner{
    position: relative;

    img{
       width: 54px;
       height: 54px;
     
       
     }
    .greenDot{
        position: absolute;
width: 9.33px;
height: 9.33px;
border-radius: 50%;
display: inline-block;
right: 10px;
bottom: 15px;

background: #1BAC21;

        
    }
}
     
    }

 }
 .messages{
     margin-top: 20px;
     max-height: 500px;
     min-height: 300px;
     overflow-y: auto;
     overflow-x: hidden;
     position: relative;
     border: 1px solid $base_color;
     border-radius: 10px;
     padding:0 5px;
    //  width: 500px;
    // max-width: 500px;
    //  max-width: 300px;
     &::-webkit-scrollbar{
         width: 5px;
        //  background-color: red;
        //  height: 10px;
     }
     &::-webkit-scrollbar-track{
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        // border-radius: 10px;
        
     }
     &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
     }
    .name{
        font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    /* or 98% */
    
    
    
    color: #AEAEAE;
    display: flex;
    justify-content: left;
    }
    .msg{
        font-weight: 400;
font-size: 10px;
line-height: 13px;
/* or 127% */
padding: 11px 18px 12px 12px;
background: #F4F4F4;
border-radius: 7px;
width: 70%;
word-wrap: break-word;

color: #3B3B3B;
margin: 8.5px 0;
    }
    .date{
        font-weight: 500;
font-size: 13px;
line-height: 13px;
/* or 98% */


color: #AEAEAE;
display: flex;
justify-content: left;

    }
     .msg-left{
        margin-top: 20px;

     }
     .msg-right{
         margin-top: 20px;
         .name{
             margin-left: auto;
             justify-content: right;
             
         }
         .msg{
             margin-left: auto;
             background-color: $base_color;
             color: white;
         }
         .date{
             justify-content: right;
         }

     }
 }
 .bottom-Content{
  
     width: 100%;
    //  border: 1px solid red;
    //  position: absolute;
     bottom: 0px;
     display: flex;
     margin-top: 10px;
     align-items: center;
     gap: 19px;
     
     .left{
         position: relative;
         width: 100%;
         height: 40px;


background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.24);
padding:9px 10px;

border-radius: 9px;
.icons{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
    right: 10px;
}
.upload{
  

}
.emoji{
   

}
.emojiPicker{
    // border: 1px solid red;
    position: absolute;
    top: 30px;
    right: 0px;
}
         input{
             width: 100%;
             height: 100%;
             border-radius: 9px;
             border: none;
             padding-right: 55px;
         }
     }

 }
}}

.studentSearch{
    justify-content: center;
    margin-top: 10px;
    position: relative;
    input{
        width: 80%;
        padding: 5px;

    }
    .student-list_parent{
        
        width: 80%;
        margin-bottom: 5px;
     z-index: 1000;
     background-color: $base_color;
        position: absolute;
        max-height: 150px;
        top: 0;
        overflow-y: auto;
        .studentList{
            padding: 5px;
            color: white;
            cursor: pointer;
            border-bottom: 1px solid white;
        }
    }
}