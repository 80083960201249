.analyticsMain{
    .top{
        justify-content: space-between;
        .heading{
            font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

letter-spacing: -0.019em;

color: #333333;
        }
        select{
            width: 186px;
height: 46px;


background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
border-radius: 10px;
font-weight: 400;
font-size: 16px;
line-height: 19px;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background-image: url('data:image/svg+xml;utf8,<svg width="19" height="9" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99992 7.58586L16.2928 0.292969L17.707 1.70718L8.99992 10.4143L0.292818 1.70718L1.70703 0.292969L8.99992 7.58586Z" fill="black"/></svg>');
background-repeat: no-repeat;
background-position-y: 50%;
background-position-x: 96%;
/* identical to box height */


color: #3B3B3B;
border: none;
padding: 10px;
        }
    }
    .content{
        gap: 33px;
        align-items: flex-start;
        @media screen and (max-width: 968px) {
            flex-direction: column;
              }
        .leftSectionTop{
            margin-top: 26px;
            gap: 22px;
            // align-items: flex-start;
            // justify-content: flex-start;
            .box{
                // width: 284.18px;
// height: 129.33px;
padding-bottom: 10px;


background: #FFFFFF;
border: 0.928571px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
border-radius: 18.5714px;
.heading{
    font-weight: 600;
font-size: 14px;
line-height: 32px;
/* or 229% */


letter-spacing: -0.019em;

color: #3B3B3B;
padding: 10px;
padding-left: 14px;
// margin-bottom: 26px;
}
            }
            
        }
        .rightSectionTop{
            margin-top: 26px;
            background: #FFFFFF;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
border-radius: 13px;
padding:16px;
width: 100%;
.heading{
    font-weight: 500;
font-size: 19.0599px;
line-height: 25px;
/* or 133% */

letter-spacing: -0.019em;

/* Heading */

color: #3B3B3B;

}
.question{
  justify-content: space-between;  
//   gap: 20px;
margin-bottom: 14px;

  .left{
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    /* identical to box height, or 195% */
    
    
    letter-spacing: -0.019em;
    
    color: #000000;
      .index{
margin-right: 5px;
      }
      .qn{

      }
  }
  .views{
    // width: 33.9px;
    padding: 5px;

    height: 26.19px;
    justify-content: center;
    
    background: #0090FF;
    border-radius: 5px;
    font-weight: 500;
font-size: 12px;
line-height: 25px;
/* or 212% */

letter-spacing: -0.019em;

color: #FFFFFF;
  }
}
        } 
        &.game-parameters{
            flex-flow: row wrap;
            margin-top: 40px;
            .info{
                width: 248px;
                height: 125px;
              padding: 21px 20px;
              padding-right: 0;
                
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.2);
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                .heading{
                    font-weight: 500;
                    margin-bottom: 15.5px;
font-size: 18px;
// line-height: 9px;
/* identical to box height, or 50% */



color: #3B3B3B;
                }
                .content{
                    font-weight: 600;
font-size: 45px;
line-height: 54px;


color: #3B3B3B;
img{
    margin-right: 3px;
    width: 34.5px;
    height: 34.5px;
}
                }
            }
        }
       

    }
    
    .bottomContent{
        margin-top: 55px;
        gap: 59px;
        @media screen and (max-width: 1200px) {
           flex-direction: column;
              }
        .left{
            .heading{
                margin-bottom: 30px;
                font-weight: 500;
font-size: 25px;
line-height: 25px;
/* identical to box height, or 102% */


letter-spacing: -0.019em;

            }
            .progressBox{
                padding: 0 8px;
                height: 115px;
                background: #FFFFFF;
                width: 320px;


                border: 0.71875px solid rgba(194, 194, 194, 0.5);
                box-shadow: 0px 0.71875px 18.6875px rgba(0, 0, 0, 0.07);
                border-radius: 14.375px;
                margin-bottom: 22px;
                @media only screen and (max-width: 1200px) {
                    width: 350px;

                  }
                .details{
                .name{
                    font-weight: 500;
                    font-size: 19.8796px;
                    line-height: 22px;
                    /* identical to box height, or 112% */
                    
                   
                    
                    color: #3B3B3B;
                }
                .date{
                    font-weight: 500;
                    font-size: 8.1523px;
                    line-height: 16px;
                    /* identical to box height, or 196% */
                    
                  
                    
                    color: #7A7A7A;
                }}
            }
            .classProgress{
                background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
border-radius: 14.375px;
padding-left: 30px;
font-weight: 600;
font-size: 19.8796px;
line-height: 22px;
/* identical to box height, or 112% */


color: #000000;
            }

        }
    }
}

// new design

.tabs-cover{
    align-items: center;
    text-align: center;
    justify-content: space-between;
    gap: 14px;
    .select-section{
        .inner{
            gap: 5px;
            span{
                font-weight: 600;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.0327273px;

color: #000000;

            }
            select{
                background: #FFFFFF;
border: 0.5px solid rgba(0, 0, 0, 0.2);
border-radius: 3px;
padding: 6px;
font-weight: 300;
font-size: 12px;
line-height: 15px;
font-style: italic;


/* identical to box height */


letter-spacing: 0.0327273px;

// color: rgba(0, 0, 0, 0.2);
-webkit-appearance: none;
background: url("../../assets/dropDown.svg") no-repeat right;
background-position-x: 96%;

// background-repeat: no-repeat;
// background-position-x: 90%;


            }
            .select-1{
width: 112px;

            }
            .select-2{
                margin-left:14px ;
                width: 209px;

                
                            }
        }
    }

}
.analytics-topbar{
    width: 280px;
    margin-left: 32px;

}

.tabpanel-content {
    // margin-top: 30px;
    .header {
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      margin-bottom: 25px;
    //   border: 1px solid red;
    }
    .top-section {
      display: flex;
      margin-top: 24px;
      flex-direction: row;
      gap: 20px;
    //   border: 1px solid red;

  
      .top-section-left {
        width: 30%;
  
        display: flex;
        flex-direction: column;
        gap: 21px;
        // flex-flow: row wrap;
        .top-section-left-top {
          width: 325px;
          height: 142px;
          padding: 17.9px 22px 25px 19px;
  
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          border-radius: 7px;
  
          .upper {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
          .lower {
            margin-top: 40px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
          }
  
          .large-font {
            font-size: 36px;
          }
        }
  
        .top-section-game-left-top {
          width: 100%;
          height: 50%;
          padding: 17.9px 22px 25px 19px;
          cursor: pointer;
  
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          border-radius: 7px;
  
          .upper {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
          .lower {
            margin-top: 40px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
          }
  
          .large-font {
            font-size: 36px;
          }
        }
  
        .top-section-left-bottom {
          width: 325px;
  
          height: 142px;
  
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
  
          box-sizing: border-box;
          border-radius: 7px;
          .upper {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
          .lower {
            margin-top: 40px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
          }
          .large-font {
            font-size: 36px;
          }
        }
  
        .top-section-game-left-bottom {
          width: 100%;
          height: 50%;
          cursor: pointer;
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.2);
  
          box-sizing: border-box;
          border-radius: 7px;
          .upper {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
          .lower {
            margin-top: 40px;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
          }
          .large-font {
            font-size: 36px;
          }
        }
      }
      .top-section-middle {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        // width: 440px;
        height: 305px;
        position: relative;
        border-radius: 7px;
        overflow: hidden;
        width: 35%;
  
        // display: flex;
        // flex-direction: column;
  
        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          // border: 1px solid red;
          margin-top: 40px;
          justify-content: space-between;
          //     font-weight: 500;
          // font-size: 24px;
          // line-height: 29px;
        }
      }
      .top-section-right {
        width: 35%;
        overflow: hidden;
  
        // width: 440px;
        height: 305px;
  
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
  
        position: relative;
        border-radius: 7px;
        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          // border: 1px solid red;
          margin-top: 40px;
          justify-content: space-between;
          //     font-weight: 500;
          // font-size: 24px;
          // line-height: 29px;
        }
      }
    }
  }
  .most-bottom {
    display: flex;
    gap: 12px;
    // justify-content: space-between;
  
    max-width: 100%;
    flex-flow: wrap;
  
    .most-bottom-left {
      height: 314px;
      padding: 10px;
  
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      position: relative;
  
      .header-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }
        .middle-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 23px;
        }
      }
      .middle-section {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        justify-content: space-between;
  
        .left {
          .info {
            display: flex;
            align-items: center;
            gap: 9px;
          }
        }
        .right {
          // margin-left: 50px;
          overflow: hidden;
  
          .heading {
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            margin-top: 25px;
  
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .spacing {
    padding: 17.9px 22px 25px 19px;
  }
  
  .bottom-section {
    margin-top: 95px;
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    gap: 225px;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      width: 100%;
      .performance {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        margin-right: 20px;
      }
      .select-new{
        margin-left: auto;
        

      }
    }
    .right {
      .right-top {
        display: flex;
        // width: 50%;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
  
        .group {
          display: flex;
          align-items: center;
          gap: 27px;
          span {
            margin-top: 5px;
          }
        }
      }
      .right-bottom {
        display: flex;
        margin-top: 13px;
        align-items: flex-end;
        gap: 20px;
  
        input {
          width: 209px;
          padding: 8px;
          height: 26px;
  
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          border-radius: 3px;
        }
      }
    }
  }
  .btn-analytics {
    width: 97px;
    height: 32px;
    left: 536px;
    top: 741px;
  
    // background: #c4c4c4;
    background-color: $base_color;
    border-radius: 7px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    border: none;
    color: white;
  }

  .btn-analytics-new{
    background-color: #DB4C86;
  
  }
  .btn-analytics-new1{
    background-color: #6A51BE;  
  }
  
  .btn-cancel {
    width: 97px;
    height: 32px;
    left: 536px;
    top: 741px;
  
    // background: #c4c4c4;
    background-color: #c4c4c4;
    border-radius: 7px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    border: none;
    color: white;
  }

  .modal-container-analytics {
    max-width: 1129px;
    min-width: 800px;
    // min-height: 337px;
    max-height: 773px;
    min-height: 500px;
    background: #ffffff;
    padding: 47px 45px 30px 37px;
    border-radius: 7px;
    // overflow: scroll;
  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-container-inner-analytics {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  .tabs-cover-new{
      position: absolute;
      top: 0;
      right: 76px;

  }

//   weekly results
.weeklyquiz-container{
    .header{
        font-weight: 700;
font-size: 24px;
line-height: 29px;
margin-top: 30px;
margin-bottom: 28px;
    }
    .content{
        .accordion-content{
            width: 75%;
            .accordion-heading-1{
                font-weight: 500;
font-size: 14px;
line-height: 17px;
            }
        }
    }
   
}

.accordion-inner-content{
    // padding: 31px 36px 58px 47px;
    display: flex;
    // align-items: center;
    padding-right: 10px;
    justify-content: space-between;
    .leftContent{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 30%;
        .time-content{
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
        }
    }
    .right-content{
        width:70% ;
        font-weight: 400;
font-size: 12px;
line-height: 15px;
.questions-section{
    align-items: flex-start;
   

}
    }
}

.btn-new-export{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.styleCell{
}
.question{
  margin-bottom: 25px;
 
  width: 100%;

.text{


  font-weight: 400;
font-size: 12px;
line-height: 15px;
margin-bottom: 7px;
}
.progress-cover{
  width: 100%;

  
  height: 15.67px;
margin-top: 10px;


background: $base_color;
border-radius: 245px;
.percentage{
font-weight: 400;
font-size: 10px;
line-height: 15.67px;
padding-right: 7px;
text-align: right;


color: #FFFFFF;
}
}
}

.btn-anlytics-outer{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}


.inner{
  gap: 5px;
  span{
      font-weight: 600;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.0327273px;

color: #000000;

  }
  select{
      background: #FFFFFF;
border: 0.5px solid rgba(0, 0, 0, 0.2);
border-radius: 3px;
padding: 6px;
font-weight: 300;
font-size: 12px;
line-height: 15px;
font-style: italic;


/* identical to box height */


letter-spacing: 0.0327273px;

// color: rgba(0, 0, 0, 0.2);
-webkit-appearance: none;
background: url("../../assets/dropDown.svg") no-repeat right;
background-position-x: 96%;

// background-repeat: no-repeat;
// background-position-x: 90%;


  }
  .select-1{
width: 112px;

  }
  .select-2{
      margin-left:14px ;
      width: 209px;

      
                  }
}