.displayNone{
    display: none;
}

.loadingMask {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #444444;
    opacity: 0.7;
}

.panelLoad {
    z-index: 1002;
    margin-left: -20px !important;
    margin-top: -35px !important;
    -webkit-animation: panel 2.2s infinite forwards !important;
    animation: panel 2.2s infinite forwards !important;
}

.panel-spinner {
    margin: 0 auto !important;
    /* position: absolute !important; */
    left: 50% !important;
    top: 50% !important;
}

.loadingSpinner{
    position:absolute;
    left:50%;
    top:50%;
}

.circle {
  animation:spin 3s linear infinite both;
  background:#AFD000;
  border-radius:100vmax;
  /* margin:calc(50vh - 5vmin) calc(50vw - 1vmin); */ /* Half the longest viewport width minus half the width/height of the circle. */
  margin:60vh calc(50vw - 1vmin);
  height:5vmin;
  position:absolute;
  width:5vmin;
}
.circle + .circle {
  animation:spin 3s linear 1s infinite both;
  background:#AFD000;
}
.circle + .circle + .circle {
  animation:spin 3s linear 2s infinite both;
  background:#AFD000;
}
@keyframes spin {
  0% {
    transform: rotate(360deg) translate(0vmax,-10vmax);
	}
  50% {
    transform: rotate(180deg) translate(0vmax,0vmax);
  }
	100% {
    transform: rotate(0deg) translate(0vmax,-10vmax);
	}
}


.bulbaSpinnerDialogBoxPanel {
	padding: 0px 10px 10px 10px;
	border-radius: 8px;
}

.bulbaSpinner {
	position: absolute;
	top: calc(50% - 150px);
	left: calc(50% - 150px);
	width: 300px;
	height: 300px;
	border-radius: 50%;
	perspective: 800px;
}

.bulbaSpinnerInner {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.bulbaSpinnerInner.one {
	left: 0%;
	top: 0%;
	animation: rotate-one 1s linear infinite;
	border-bottom: 5px solid white;
}

.bulbaSpinnerInner.two {
	right: 0%;
	top: 0%;
	animation: rotate-two 1s linear infinite;
	border-right: 5px solid crimson;
}

.bulbaSpinnerInner.three {
	right: 0%;
	bottom: 0%;
	animation: rotate-three 1s linear infinite;
	border-top: 5px solid cadetblue;
}

#bulbaSpinner2Group {
	position: relative;
	width: 100px;
	height: 15px;
	margin: auto;
}

.bulbaSpinner2Group {
	position: absolute;
	top: 0;
	background-color: rgb(0, 191, 255);
	width: 10px;
	height: 10px;
	animation-name: bounce_bulbaSpinner2Group;
	-o-animation-name: bounce_bulbaSpinner2Group;
	-ms-animation-name: bounce_bulbaSpinner2Group;
	-webkit-animation-name: bounce_bulbaSpinner2Group;
	-moz-animation-name: bounce_bulbaSpinner2Group;
	animation-duration: 1.69s;
	-o-animation-duration: 1.69s;
	-ms-animation-duration: 1.69s;
	-webkit-animation-duration: 1.69s;
	-moz-animation-duration: 1.69s;
	animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-direction: normal;
	-o-animation-direction: normal;
	-ms-animation-direction: normal;
	-webkit-animation-direction: normal;
	-moz-animation-direction: normal;
	transform: scale(.3);
	-o-transform: scale(.3);
	-ms-transform: scale(.3);
	-webkit-transform: scale(.3);
	-moz-transform: scale(.3);
	border-radius: 9px;
	-o-border-radius: 9px;
	-ms-border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
}

#bulbaSpinner2Group_1 {
	left: 0;
	animation-delay: 0.68s;
	-o-animation-delay: 0.68s;
	-ms-animation-delay: 0.68s;
	-webkit-animation-delay: 0.68s;
	-moz-animation-delay: 0.68s;
}

#bulbaSpinner2Group_2 {
	left: 13px;
	animation-delay: 0.85s;
	-o-animation-delay: 0.85s;
	-ms-animation-delay: 0.85s;
	-webkit-animation-delay: 0.85s;
	-moz-animation-delay: 0.85s;
}

#bulbaSpinner2Group_3 {
	left: 27px;
	animation-delay: 1.01s;
	-o-animation-delay: 1.01s;
	-ms-animation-delay: 1.01s;
	-webkit-animation-delay: 1.01s;
	-moz-animation-delay: 1.01s;
}

#bulbaSpinner2Group_4 {
	left: 40px;
	animation-delay: 1.18s;
	-o-animation-delay: 1.18s;
	-ms-animation-delay: 1.18s;
	-webkit-animation-delay: 1.18s;
	-moz-animation-delay: 1.18s;
}

#bulbaSpinner2Group_5 {
	left: 54px;
	animation-delay: 1.35s;
	-o-animation-delay: 1.35s;
	-ms-animation-delay: 1.35s;
	-webkit-animation-delay: 1.35s;
	-moz-animation-delay: 1.35s;
}

#bulbaSpinner2Group_6 {
	left: 67px;
	animation-delay: 1.52s;
	-o-animation-delay: 1.52s;
	-ms-animation-delay: 1.52s;
	-webkit-animation-delay: 1.52s;
	-moz-animation-delay: 1.52s;
}

#bulbaSpinner2Group_7 {
	left: 81px;
	animation-delay: 1.69s;
	-o-animation-delay: 1.69s;
	-ms-animation-delay: 1.69s;
	-webkit-animation-delay: 1.69s;
	-moz-animation-delay: 1.69s;
}

#bulbaSpinner2Group_8 {
	left: 94px;
	animation-delay: 1.86s;
	-o-animation-delay: 1.86s;
	-ms-animation-delay: 1.86s;
	-webkit-animation-delay: 1.86s;
	-moz-animation-delay: 1.86s;
}

@keyframes bounce_bulbaSpinner2Group {
	0%		{transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{transform:scale(.3); background-color :rgb(255,255,255);}
}

@-o-keyframes bounce_bulbaSpinner2Group {
	0%		{-o-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-o-transform:scale(.3); background-color: rgb(255,255,255);}
}

@-ms-keyframes bounce_bulbaSpinner2Group {
	0%		{-ms-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-ms-transform: scale(.3); background-color:rgb(255,255,255);}
}

@-webkit-keyframes bounce_bulbaSpinner2Group {
	0%		{-webkit-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-webkit-transform: scale(.3); background-color: rgb(255,255,255);}
}

@-moz-keyframes bounce_bulbaSpinner2Group {
	0%		{-moz-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-moz-transform: scale(.3); background-color: rgb(255,255,255);}
}

@keyframes rotate-one {
	0% 		{ transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);}
	100% 	{ transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);}
}

@keyframes rotate-two {
	0% 		{transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);}
	100%	{transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);}
}

@keyframes rotate-three {
	0% 		{transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);}
	100%	{transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);}
}