.mainDashboard{
    // .main
    min-height: 100vh;
    background-image: url("../../assets//backgroundHome.png");
    background-position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
  background-attachment: fixed;
    background-color: black;
    .left-Sidebar{
        width: 96px;
        position:fixed;
        border: 1px solid #EF5B2C;
box-sizing: border-box;
box-shadow: 0px 0px 10px #EF5B2C;
border-radius: 7px;
// position: relative;

       top: 46px;
       bottom: 30px;
      
background: $base_color;
        left: 21px;
        .groupItem{
            width: 100%;
            position: relative;
            margin-bottom: 10px;
           

            img{
                width: 50.76px;
height: 50.5px;

            }
            @media only screen and (max-width: 1400px) {
                margin-bottom: 5px;
                img{
                // border: 1px solid red;
             
                width: 40px;
                height: 50px;
                }
            }
        }
        .middle-Content-sidebar{
            // margin-top: 40px;
        }

        .outer{
            display: flex;
            position: relative;
            align-items: center;
            // justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .topIcon{
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
                img{
                width: 66px;
                height: 107px;



               
            }}
          
        }
        .individual{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
        
        .settings{
            position: absolute;
            bottom: 23px;
            left: 50%;
            transform: translateX(-50%);
            @media only screen and (max-width: 1400px) {
               bottom: 5px;
                img{
                // border: 1px solid red;
         
                width: 20px;
                height: 20px;
             
                }
            }
        }
    // border: 1px solid red;
//     .outer{
//         .topIcon{
           

// display: flex;
// align-items: center;
// justify-content: center;
// img{
//     width: 66px;
//     height: 107px;
//     @media only screen and (max-width: 1400px) {
//         width: 40px;
//     height: 70px;
//       }
// }


//         }
//         .middle-Content-sidebar{
//             margin-top: 30px;
            
//             .groupItem{
//                 display: flex;
//             align-items: center;
//             position: relative;
//             justify-content: center;
//             flex-direction: column;
//             // gap: 6.11px;
            
//             margin-bottom: 18px;
//                 img{
//                     width: 52.76px;
// height: 68.5px;
//                 }
//                 span{
//                     font-weight: 500;
// font-size: 13px;
// line-height: 13px;
// /* identical to box height, or 98% */



// color: #FFFFFF;
//                 }
//                 @media only screen and (max-width: 1400px) {
//                     margin-bottom: 10px;
//                     img{
//                         width: 35px;
    
//                         height: 48px;
//                     }
//                     span{
//                         font-size: 9px;
//                     }
//                   }
//             }
          
        
//         }
//         .individual{
//             margin-top: 108px;
//             @media only screen and (max-width: 1400px) {
//                 margin-top: 50px;
//               }
            
//             .groupItem{
//                 display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//             // gap: 6.11px;
           
// margin-bottom: 25px;
//                 img{
//                     width: 52.76px;
// height: 68.5px;
//                 }
//                 span{
//                     font-weight: 500;
// font-size: 13px;
// line-height: 13px;
// /* identical to box height, or 98% */



// color: #FFFFFF;
//                 }
//                 @media only screen and (max-width: 1400px) {
//                     img{
//                         width: 25px;
    
//                         height: 38px;
//                     }
//                     span{
//                         font-size: 9px;
//                     }
//                   }}

//         }
//         .settings{
//             position: absolute;
//             bottom: 20px;
//             left: 50%;
//             transform: translateX(-50%);
//             img{
//                 width: 18px;
// height: 19px;
//             }
//             @media only screen and (max-width: 1400px) {
//                bottom: 10px;
//               }
            

//         }
//     }


    }
    .middle-Content{
        background-color: white;
        width: 70%;
        z-index: 1000;

      

border-bottom-left-radius: 7px;
border-top-left-radius: 7px;


// height: 100%;
padding: 24px 49.91px 69px 43px;
&.middle-Content-Student-new{
    width: 100%;
    border-radius: 7px;
}
.search-Container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    .search-Box{
        width: 171px;
height: 46px;
position: relative;
input{
    border: 1px solid rgba(0, 0, 0, 0.18);

    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding-left: 58px;
    &::placeholder{
        font-weight: 400;
font-size: 16px;
line-height: 19px;
/* identical to box height */


color: #3B3B3B;

    }
}
img{
    position: absolute;
    width: 21px;
    height: 21px;
    top: 50%;
    left: 16.55px;
    transform: translateY(-50%);
}
    }
}
.boxes{
    margin-top: 37px;
    display: grid;
    // grid-template-columns: 309px 309px;
    grid-template-columns: repeat(2,minmax(250px,400px));

    gap: 38px;
   
    @media only screen and (max-width: 1260px) {
        grid-template-columns: repeat(2,minmax(250px,309px));
    
           
          }
          @media only screen and (max-width: 1100px) {
            grid-template-columns: repeat(2,minmax(250px,200px));
        
               
              }
              @media only screen and (max-width: 1000px) {
                grid-template-columns: repeat(1,minmax(350px,200px));
            
                   
                  }
    @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(3,minmax(250px,309px));

       
      }
    .inner-box{
position: relative;
border-radius: 19.1827px;
padding: 9px 10px 9px 26px;
.heading{
    font-weight: 500;
font-size: 12px;
width: 114px;
height: 27px;
text-align: center;
display: block;
line-height: 27px;
border-radius: 25px;


}
.middle-content{
margin-top: 39px;
.classroom{
    font-weight: 700;
font-size: 25px;
line-height: 12px;
margin-bottom: 13px;
}
.date{
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    
}
}
.bottom-content{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
.bottom-content-inner{
    width: 41px;
    height: 34px;
    font-weight: 700;
    font-size: 19px;
    // line-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
 
    
    border-radius: 8px;
    // align-self: flex-end;
}


   
}
.innerImage{
    position: absolute;
    top: 23px;
    right: 34px;
    width: 56px;
height: 57px;
@media only screen and (max-width: 1400px) {
    width: 46px;
height: 47px;

  }
  @media only screen and (max-width: 968px) {
    width: 36px;
height: 37px;
  }
}

    }
    .inner-box-1{
        .heading{
            background: #F0ECA7;
        }
        .bottom-content-inner{
            background: #F0ECA7;
        }


background: #FFFCBE;
    }
    .inner-box-2{
        background: #F69C80;
        .heading{
            background: #D97F63;
        }
        .bottom-content-inner{
            background: #D97F63;
        }
    }
    .inner-box-3{
        background: #FFD34E;
        .heading{
            background: #F2BC1C;
        }
        .bottom-content-inner{
            background: #F2BC1C;
        }
    } .inner-box-4{
        background: #FF7D54;
        .heading{
            background: #E8643B;
        }
        .bottom-content-inner{
            background: #E8643B;
        }
    } .inner-box-5{
        background: #FDA98F;
        .heading{
            background: #D97F63;

        }
        .bottom-content-inner{
            background: #D97F63;

        }
    } .inner-box-6{
        background: #FFBF00;
        .heading{
            background: #E2AA00;
        }
        .bottom-content-inner{
            background: #E2AA00;
        }
    } 
}


    
.bottom-content-middle{
   
    .top-section{
        justify-content: space-between;
        margin-top: 96px;
        .heading{
            font-weight: 600;
font-size: 21px;
line-height: 25px;
        }
 select{
    opacity: 0.8;
    width :
123.64px;
height :
26.57px;
border: none;
background: #FFFFFF;
box-shadow: 0px 2.96104px 10.3636px rgba(0, 0, 0, 0.1);
border-radius: 5.92208px;
font-weight: 400;
font-size: 11.8442px;
line-height: 14px;

color: #666666;

 }

    }
    .end-section{
        margin-top: 36px;
        margin-bottom: 69px;
    }
}}
    .right-Sidebar{
z-index: 1000;

        // width: 362px;
        width: 30%;
        padding-left: 31px;
        border-bottom-right-radius: 7px;
border-top-right-radius: 7px;
padding-bottom: 20px;
        padding-right: 42px;
        // border: 1px solid red;
        // position: fixed;
        // right: 0;
        

background: #FFFCBE;
.date{
    font-weight: 600;
font-size: 21px;
line-height: 25px;
text-align: center;
margin-top: 45px;

color: #474747;
}
.day{
    // display: flex;
    margin: auto;
    width: 170px;
height: 171px;


background: #F48B58;
box-shadow: 0px 4px 17px #FFDAB7;
border-radius: 34px;
margin-top: 17px;
padding: 32px 48px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
color: white;
gap: 5px;
@media only screen and (max-width: 968px) {
    width: 150px;
   
}
    
.month
{
    font-weight: 500;
font-size: 35px;
line-height: 42px;
}
.dayValue{
    font-weight: 700;
font-size: 50px;
line-height: 61px;
}
margin-bottom: 82px;

}
.info{
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    // border: 1px solid  red;
    width: 100%;
    gap:18px;
.infoBox{
    padding: 19px 26px 18px 23px;
    // width: 289px;
    width: 100%;
// height: 130px;


background: #FFFFFF;
box-shadow: 0px 4px 22px #FFDAB7;
border-radius: 19px;
.top{
    width: 36px;
height: 36px;


background: #82CEDD;
border-radius: 6px;
span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
font-size: 21px;
line-height: 25px;
height: 100%;
// border: 1px solid red;
display: flex;
align-items: center;

color: #FFFFFF;

}

}
.middle{
    font-weight: 500;
font-size: 20px;
overflow-wrap: break-word;
line-height: 24px;
margin-bottom: 4px;
margin-top: 10px;

color: #3B3B3B;


}
.bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span{
        font-weight: 500;
font-size: 16px;
line-height: 19px;


color: #C4C4C4;

    }
    img{
        width: 8px;
        height: 13px;

    }

}
}
}
.announcement{
    .heading{
        font-weight: 700;
font-size: 21px;
margin-top: 10px;
line-height: 48px;
margin-bottom: 23px;
/* or 229% */


letter-spacing: -0.022em;

color: #3B3B3B;
    }
}
    }
}



// for active menus
.active{
    position: absolute;
    width: 9px;
height: 9px;
// left: 95px;
border-radius: 50%;
// top: 576px;
right: -5px;
bottom: 0;

background: #F75A5A;
@media only screen and (max-width: 1400px) {
   width: 7px;
   height: 7px;
}
}