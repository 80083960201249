

.calendar-Container{
    margin-top: 43px;
}
.container-dialog{

    .calendar{
    .top{
        // background-color: red;
        justify-content: space-between;
        span{
            font-weight: 500;
font-size: 21.251px;
line-height: 26px;

color: #979797;
margin-left: 30px;

        }
        img{
            width: 26.5px;
            height: 26.5px;

        }
    }
    .mainSection{
        display: flex;
        // align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 19px;
        .groupItem{
            // border: 1px solid red;
            display: flex;

            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;
            margin-top: 26px;
select{
    width: 100%;
   
height: 39.73px;
border: none;
font-weight: 400;
font-size: 10.6255px;
line-height: 16px;
/* identical to box height */


/* Heading */

color: #3B3B3B;

/* Grey 100 */

background: #F5F5F5;
border-radius: 6.07171px;
}
            .contentTime{
                display: flex;
                align-items: center;
                gap: 18.6px;
                width: 100%;
                .child-1{
                width: 100%;
                .react-datepicker-wrapper{
                    margin-top: 10px;
                    .react-datepicker__input-container{

                        input{
                            border-radius: 8px;
                            height: 39.73px;
                            width: 100%;
                            background: #E8DEE3;
                            text-align: center;
                            border: none;
                            font-weight: 400;
                            font-size: 10.6255px;
                            line-height: 16px;
                        }
                    }

                
             
                }

                /* identical to box height */
                
                
                /* Heading */
                
                color: #3B3B3B;
                // position: relative;
                // .child-2{
                //     position: relative;
                //     width: 100%;
                // }
                // .arrow{
                //     // pos
                //     position: absolute;
                //     right: 10px;
                //     top: 50%;
                //     transform: translateY(-50%);
                // }

                }
            }
            img{
                width: 18px;
                height: 18px;
// flex: 10%;
            }
            .content{
                flex: 100%;
                textArea{
                    width: 100%;
                    background: #F5F5F5;
                    border-radius: 8px;
                    border: none;
                    padding: 10px;
                }
            }
        }
        .groupItem-last{
            display: flex;
            align-items: center;
            margin-left: 40px;
            gap:20px;
            label{
            font-weight: 500;
font-size: 11.3845px;
line-height: 14px;

color: #414141;
            }
        }
        .button-section{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button{
                width: 142.85px;
height: 36.51px;
left: 762.63px;
top: 868.39px;

background: $base_color;
border-radius: 4.36665px;
border: none;
font-weight: 400;
font-size: 13.1px;
line-height: 16px;

text-align: center;
letter-spacing: -0.727776px;

color: #FFFFFF;

            }
        }
    }

    }
    

  
}

// custom date picker
.example-custom-input{
  
    background: #E8DEE3;
border-radius: 8px;
height: 39.73px;
width: 100%;
border: none;
font-weight: 400;
font-size: 10.6255px;
line-height: 16px;
/* identical to box height */


/* Heading */

color: #3B3B3B;
// width: 190.25px;

}

// calendar override css
.fc .fc-daygrid-day-frame{
//    border:.74px solid #E8E8E8;
//    padding: 17px;
//    width: 150px;
//    Width 
// 115.17px
// Height 
// 105.51px
}

// .fc-h-event .fc-event-title{
//     background-color:#FFFFFF;
//     padding: 4px  16px;
//     font-weight: 400;
// font-size: 10.3799px;
// line-height: 16px;
// // border: 1px solid white;
// overflow: scroll;
// // word-break: n;
// /* identical to box height */


// /* Heading */

// color: #3B3B3B;
// }.fc-h-event .fc-event-title-container.fc-h-event .fc-event-title-container.fc-h-event .fc-event-title-container
.fc-h-event{
    border:none !important;
}
.fc-h-event .fc-event-title-container{
    // background-color: #FCDED4;
    border: none ;
   
/* identical to box height */


/* Heading */

color: #3B3B3B;
display: flex;
height: 24px;
align-items: center;
border-radius: 3.45995px;

&::before{
    content: "";
    width: 3.46px;
    display: inline-block;
    // height: 10px;
    height: 100%;
    background-color:  #FF4345;
    border-bottom-left-radius: 3.45995px;
    border-top-left-radius: 3.45995px;
    
}
}
.fc-h-event .fc-event-title {
    font-weight: 400;
    font-size: 10.3799px;
    line-height: 16px;
    padding: 4px 16px;


}
.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active{
    background-color: $base_color !important;
    padding: 10.3799px 20.7597px 11.2448px !important;



box-shadow: inset 0px -3.45995px 0px rgba(0, 0, 0, 0.15) !important;
border-radius: 6.9199px !important;
text-transform: capitalize !important ;
color: white !important;

}
.fc-button-primary{
    background-color: #E8E8E8 !important;
    text-transform: capitalize !important;
    padding: 10.3799px 20.7597px 11.2448px !important;
    color: #636363 !important;
    border: none !important;


// border-radius: 6.9199px !important;
}
.fc .fc-button:disabled{
  
    font-weight: 500 !important;
font-size: 13.8398px !important;
line-height: 17px !important;
/* identical to box height */



/* Heading */

color: #3B3B3B !important;
}
.fc .fc-toolbar-title{
    // background-color: red;
    font-weight: 600;
font-size: 30.28px;
line-height: 37px;
/* identical to box height */


/* Heading */

color: #3B3B3B;

}
.fc .fc-daygrid-day-number{
    padding: 10px !important;
}


// table
.tableCalendar{
    div:nth-child(1){
margin-top: 0 !important;
    }
   
}


// calendar

.main-Calendar-dialog{
    .inner{
    // border: 1px solid red;
    display: flex;
    align-items: center;
    gap: 35px;
    flex-direction: row;
    margin-bottom: 100px;

    .groupItem-invite{
    // border: 1px solid red;
    label{
    // border: 1px solid red;
   

    }

        input{
            width: 327px;
        }
    }
    }
}

                // custom date calendar
 .custom-date-calendar{
// border: 1px solid red;
// display: flex;

img{
    position: absolute;
    top: 50%;
    margin-top: 5px;
    transform: translateY(-50%);
    right: 18px;

}
                }
// calendar title
.calendarTitle{
    height: 37px;

width: 90%;
top: 503px;
padding: 5px;

border: 1px solid #C4C4C4;
border-radius: 3px;
&::placeholder{
    font-weight: 500;
font-size: 21.251px;
// line-height: 26px;
padding: 5px;

color: #979797;
}

}

.fc-timegrid-event, .fc-v-event, .fc-event{
    // background-color: red ;
    border: none !important;
}
.fc-v-event .fc-event-title {
    color: black !important;
}