.avalabilityModelMainDiv{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .modelInnerDiv{
        width: 600px;
        height: 300px;
background: #FFFFFF;
border-radius: 20px;
font-family: "Inter";
font-style: normal;
padding: 30px;
.top{
span{
font-weight: 500;
font-size: 41px;
line-height: 32px;
letter-spacing: -0.019em;
color: #8D8D8D;
}
}
.middle{
    margin-top: 20px;
    margin-bottom: 20px;
    .timeDivMain{
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .timeDiv{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span{
                color: #A4A3A3;
                font-weight: 400;
font-size: 18px;
line-height: 32px;
margin-bottom: 10px;
            }
            input{
                width: 93px;
height: 57px;
border: 1px solid $base_color;
border-radius: 8px;
text-align: center;
color: $base_color;
font-weight: 700;
font-size: 47px;
line-height: 32px;
outline: none;
            }
        }
        .colon{
            color: $base_color;
            font-weight: 700;
            font-size: 47px;
            line-height: 32px;  
        }
    }
    .datePickerMain{
        margin-bottom: 20px;
        span{
            color: #000000;
            font-weight: 400;
font-size: 18px;
line-height: 32px;
        }
        .example-custom-input{
            border: 1px solid #E1DEDE;
border-radius: 5px;
width: 100%;
text-align: left;
background-color: transparent;
padding: 10px;
margin-top: 10px;
img{
    position: absolute;
    right: 0;
    margin-right: 20px;
}
        }
    }

    .lessonLockUnlockDiv{
        label{
            color: #000000;
            font-weight: 400;
font-size: 18px;
line-height: 32px;
margin-bottom: 10px;
        }
        .form-switch .form-check-input{
            width: 60px;
            height: 30px;
        }
        .form-switch .form-check-input:checked{
            background-color: $base_color;
            box-shadow: none;
            border: none;
            outline: 0ch;
        }
    }
}
position: relative;
.bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 150px;
    padding-right: 150px;
    button{
        border-radius: 7px;
        color: #FFFFFF;
        min-width: 160px;
        border: 0ch;
        outline: none;
        min-height: 40px;
    }
    .cancel_btn{
        background: #C4C4C4;
        margin-right: 10px;
    }
    .save_btn{
        background: $base_color;
    }
}
}
}