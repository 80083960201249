.profilePageMain{
    .topDiv{
        display: flex;
        align-items: center;
        .avatarMainDiv{
            width: 200px;
            height: 200px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $base_color;
            box-shadow: 0px 0px 34px -13px rgba(0,0,0,0.75) inset;
            -webkit-box-shadow: 0px 0px 34px -13px rgba(0,0,0,0.75) inset;
            -moz-box-shadow: 0px 0px 34px -13px rgba(0,0,0,0.75) inset;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        button{
            margin-left: 20px;
        }
    }
    
    .displayData{
        margin: 20px;
        font-size: 20px;
        .label{
            color: grey;
        }
        input{
            padding: 10px;
            border-radius: 10px;
            outline: none;
            border: 1px grey solid;
            margin-left: 10px;
        }
        .readonly{
            background-color: rgb(216, 212, 212);
        }
    }
    button{
        width: 100px;
        border-radius: 10px;
        color: white;
        outline: none;
        border: 0ch;
        height: 40px;
    }
    .saveBtn{
        background-color: $base_color;
    }
    .cancelBtn{
        background-color: rgb(192, 180, 180);
        margin-left: 20px;
    }
}