.container-layout{
    // padding: 225px 152px 265px 153px;
  background-color: $base_background;

height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-content{
        position: relative;
        width: 1135px;
       
height: 475px;


background: #FFFFFF;

box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.08);

.main-content-left{
    width: 131px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
padding-bottom: 23px;
    height: 100%;
    .topIcon{

    }
    .group-Item{
        display: flex;
        align-items: center;
         cursor: pointer;
        flex-direction: column;
        gap: 13px;
        justify-content: center;
        height: 133px;
        position: relative;
        width: 100%;
        span{
            font-weight: 500;
font-size: 18px;
line-height: 22px;
        }

    }
    .groupItem-active::before{
        content: "";
        position: absolute;
        width: 3px;

    left: 0;
     height: 100%;



background: $base_color;
transform: matrix(-1, 0, 0, 1, 0, 0);

    }
}
.great-place-edu{
    position: absolute;
    left: 131px;
    top: -59px;
    width: 535px;
height: 610px;
background-color: $base_color;
.stemIcon{
    margin-left: 7px;
    margin-top: -5px;
}
.text-Content{
    display: block;
    margin-left: 92px;
    .welcome-msg{
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.01em;
        
        color: #FFFFFF;
    }
    .description{
        font-weight: 600;
font-size: 28px;
margin-top: 2px;
line-height: 34px;
/* identical to box height */

letter-spacing: -0.02em;

color: #FFFFFF;


    }
  

}

.great-place-edu-inner{
    position: relative;
    width: 100%;
    height: 100%;
    .sittingGirl{
        position: absolute;
        top: 341px;
        left: 250px;
        @media only screen and (max-width: 1200px) {
            width: 50%;
            height: 100%;
            top: 260px;
            
            }
            @media only screen and (max-width: 968px) {
                width: 50%;
                height: 100%;
                top: 260px;
              
                // right: 0;
                left: 130px;
                top: 282px;
                
                }

    }
}
@media only screen and (max-width: 1200px) {
    width: 480px;
  

    }
    @media only screen and (max-width: 968px) {
        width: 250px;
      
        }
        @media only screen and (max-width: 768px) {
            // width: 300px;
          
            }

}


}
    .main-content-right{
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        right: 83px;
        top: 25px;
        .heading{
            font-weight: 400;
font-size: 18px;
line-height: 22px;
/* identical to box height */

letter-spacing: -0.01em;
            .focused{
                font-weight: 700;
               
color: $base_color;
            }
        }
        form{
            margin-top: 16px;
            margin-bottom: 20px;
            .group_Item{
                width: 299px;
                display: flex;
                // align-items: center;
                gap: 9px;
                justify-content: left;
                flex-direction: column;

height: 68px;
margin-top: 16px;
.input-field{
    position: relative;
    
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;

    input{
        width: 87%;
    
    height: 100%;
    border: none;
    border-radius: 4px;

    
    
    
    padding: 3px;
    &:focus{
        border: 1px solid black;
    }
    }
    img{
        position: absolute;
        top: 50%;
     
        transform: translateY(-50%);
        right: 13px;
        
    }
}

            }
        }
    }
}

.otpBtn{
    background: $base_color;
border-radius: 8px;
width: 299px;
height: 40px;
font-weight: 600;
font-size: 18px;
line-height: 22px;
/* identical to box height */
border: none;


color: #FFFFFF;
}


// otp-mode
.otp-Mode{
    .heading{
        font-weight: 400;
font-size: 18px;
line-height: 22px;
/* identical to box height */

letter-spacing: -0.01em;
margin-top: 50px;
    }
    .otp-Numbers{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-top: 60px;
        margin-bottom: 122px;
        input{
            width: 42px;
            height: 42px;
            color: $base_color;
            font-size: 25px;
            text-align: center;

        
            
            border: 1px solid #D0D0D0;
            box-sizing: border-box;
            border-radius: 4px;

        }
    }
    .verify-btn{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// signIn-mode{
    .signIn-Mode{
        display: flex;
        align-items: center;
        flex-direction: column;
        .recover-pwd{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 500;
font-size: 14px;
line-height: 17px;


color: #8F8F8F;
margin-top: 6px;
        }
      
    }

    .recover-Password{
        // display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .heading{
            font-weight: 700;
            // position: absolute;
            // right: 100px;
font-size: 18px;
text-align: center;
line-height: 22px;
/* identical to box height */

letter-spacing: -0.01em;

color: $base_color;
        }
        .forgotPasswordSpan{
            font-weight: 400;
font-size: 18px;
margin-top: 4px;
line-height: 22px;
/* identical to box height */

letter-spacing: -0.01em;

color: #3B3B3B;
        }
    }

    .agreement{
        position: absolute;
        bottom: -70px;
text-align: left;           
font-weight: 500;
font-size: 13px;
line-height: 16px;

color: #8F8F8F;
span{
color: #5BC4FF;

}
    }

    .agreement-1{
        bottom: -20px;
    }



    // otp mode -2


    .otp-Mode-2{
        display: flex;
        align-items: center;
        flex-direction: column;
        .heading{
            font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
/* identical to box height */
color: $base_color;

letter-spacing: -0.01em;
        }
        
    }

    // forgot logo
    .forgotLogo-parent{

        .forgotLogo{
            margin-left:180px;
            margin-top: 80px;
            width: 101.94px;
            height: 115.72px;       
        }

    }
   