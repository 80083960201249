@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import "./variables.scss";
@import "./pages/authentication.scss";
@import "./pages/mainDashboard.scss";
@import "./pages/chat.scss";
@import "./pages/classRoom.scss";
@import "./pages/student.scss";
@import "./pages/calendar.scss";
@import "./pages/analytics.scss";
@import "./pages/loaderspinner.scss";
@import "./pages/profile.scss";
@import "./pages/availabilityModel.scss";
@import "./pages/leaderboardCard.scss";
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');


:root{
  // --toastify-color-success: #EF5B2C;
  // --toastify-color-error:red;
  // --toastify-toast-background:#EF5B2C !important;
  // --toastify-toast-min-height: 40px;

}


html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 16px;
  color:  #3B3B3B;;
  font-weight: 500;
  font-style: normal;
  // line-height: 19px;
}
*:focus-visible {
  outline: none !important;
}
a {
  color: inherit;
  text-decoration: none;
}
li{
  list-style: none;
}
* {
  box-sizing: border-box;
}
@font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Black.ttf") format("truetype");
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Work Sans";
    src: url("../../public/fonts/WorkSans-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  button{
    cursor: pointer;
  }


  .d-flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .d-flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  // .justify-content-space-between



  // main section every page
  .main_section{
    margin-left: calc(19px + 21px + 96px);
    padding-top: 50px;
    padding-right: 25px;
    
   
  

  
    padding-bottom: 30px;
    .main_section_inner{
      display: flex;

      // position: relative;
      flex-direction: row;
    
      border-radius: 20px;

    .notificationArea{
      position: fixed;
      top: 58px;
      right: 235px;
      .notification_inner{
        position: relative;
        img{
          width: 32px;
          height: 32px;
        }
        span{
          position: absolute;
          width: 10.67px;
          height: 10.67px;
          border-radius: 50%;
          right: 3px;
          top: 2px;
          background-color: $base_color;
        }

      }
    }
    .search-Container{
      position:fixed;
top: 55px;
right: 25px;
width: 186px;
height: 38px;
&.search-Box-new{
  position: relative;
  margin-left: auto;
  margin-bottom: 50px;
  
}
.studentSearch{
  position: absolute;
  top: 30px;
  left: 0;
  max-height: 300px;
  // width: 100%;
  width: inherit;

  .student-list_parent{
    width: 100%;
  }
 
}
.search-Box{
  position: relative;
  width: 100%;
  height: 100%;

  input{
    width: 186px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  padding-left: 35px;
  border-radius: 10px;
  }
  img{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
  }
}

    }
  }
  }

  .container-dialog{
    max-width: 850px;
    // max-height: 510px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

background: #FFFFFF;
border-radius: 25px;
padding: 20px;
  }
  .calendar-dialog{
    padding: 19px ;
    max-width: 455px ;
width: 455px ;
    border-radius: 6.07171px ;
 
    

}


  .cancel-btn{
    background: #C4C4C4;
    border-radius: 7px;
    font-weight: 500;
font-size: 24px;
line-height: 0%;
width: 164px;
/* identical to box height, or 0px */


text-align: center;

border: none;
color: #FFFFFF;
padding: 25px;
  }
  .invite-btn{
    background: #EF5B2C;
        border-radius: 7px;
    font-weight: 500;
font-size: 24px;
line-height: 0%;
border: none;
width: 164px;


/* identical to box height, or 0px */


text-align: center;

color: #FFFFFF;
padding: 25px;

  }

  // breadCrumb

  .breadcrumb-title{
    font-weight: 400;
font-size: 25px;
// line-height: 9px;
/* or 36% */

display: flex;
align-items: center;

color: #3B3B3B;
@media screen and (max-width: 968px) {
 font-size: 21px;
   }
  }

  // pause btn
  .pause-btn{

background: #F5754F;
box-shadow: inset 0px -2px 19px 7px rgba(103, 4, 5, 0.17);
border-radius: 39px;
font-weight: 600;
font-size: 15px;
line-height: 28px;
/* or 187% */

display: flex;
align-items: center;

color: #FFFFFF;
padding: 5px 38px;
border: none;
  }
  .border_Bottom{
 
    margin-top: 31px;
    // height: 1px;
    width: 100%;
    // background:rgba(0, 0, 0, 0.42) ;
  }
.border_Bottom-1{
  border: 1px solid rgba(0, 0, 0, 0.42);
}


  // accordion
  .MuiAccordion-root{
    // border: 1px solid green;
// width: 774px;
// height: 74.07px;


background: #FFFFFF;
border: 1px solid rgba(194, 194, 194, 0.5);
box-shadow: 0px 1px 26px rgba(0, 0, 0, 0.07);

  }



  // tabs 
  .MuiTab-root{
    // background-color: red;
    font-weight: 300;
font-size: 14px;
line-height: 32px;
/* or 229% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.019em;

color: #333333;
text-transform: capitalize;
}
.MuiTabs-root{
  border: none;

}

.Mui-selected{
    color: $base_color !important;
}
.MuiTabs-indicator{
    background-color: $base_color;
    bottom: 10px;
}


// for alert msg
.alert-msg{
  display: block;
  font-size: 16px;
  text-align: center;

}
.danger-msg{
  color: red;
}
.success-msg{
  color: green;
}

// accordion
.MuiAccordion-root {
  background: rgba(218, 218, 218, 0.5);
  margin-bottom: 18.31px;
  box-shadow: 0 0 0 rgb(0 0 0 / 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 10px;
}
.MuiAccordionDetails-root {
  background-color: white;

  border-bottom-left-radius: 10px;
  // padding: 31px 36px 58px 47px;
  border-bottom-right-radius: 10px;
}
#myDivId::-webkit-scrollbar {
  width: 8px;
}
#myDivId::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3); 
border-radius: 2px;
}

#myDivId::-webkit-scrollbar-thumb {
border-radius: 2px;
-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5); 
}

.active-input{
  border: 1px solid black !important;
}

.invite-new{
  margin-bottom: 5px !important;
  &:last-child{
    margin-bottom: 10px !important;
  }

  


}
.dialog-notify-msg{
  width: 400px;
  max-height: 400px;
  overflow: auto;
  overflow-x: hidden;
  .msg-notification{
    margin-top: 50px;
    word-wrap: break-word;
  }
}