.middle-Content-Classroom{
    padding-right: 0 !important;
    
    .heading-classroom{
        font-weight: 600;
        font-size: 36px;
        line-height: 9px;
      
        
        color: #3B3B3B;
        margin-top: 20px;
       
    }
    .top-classroom{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
           
            display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 25px;
        flex-flow: row wrap;
            .inner-box{
                width: 185px;
                padding: 14px;
                height: 67px;
                background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);

box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 7px;
display: flex;
align-items: center;
justify-content: space-between;


                img{
                    width: 30px;
                    height: 30px;
                   }
                .content{
                    .top{
                        margin-bottom: 8px;
                    }
                  
                    span{
                    font-weight: 500;
font-size: 12px;
line-height: 9px;

display: flex;
align-items: center;

color: #3B3B3B;
                }
            }
            }
        }
        
    }
    .main-Content-classroom{
        padding-right: 43px ;
        margin-top:47px;
        margin-bottom: 35px;
        .top{
            justify-content: space-between;
            .heading{
                font-weight: 600;
font-size: 24px;
line-height: 9px;
        // border-bottom: 1px solid red;

/* identical to box height, or 37% */


color: #3B3B3B;


            }
            .invite{
                font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #FFFFFF;
width: 81px;
line-height: 29px;
height: 29px;

text-align: center;
background: $base_color;
border-radius: 4px;
cursor: pointer;

            }
        }

    }
   
}
// .right{
    
    .right-Sidebar-classroom{
       
        background-color: white !important;
    }
    .rightSidebar-inner{
        
        .inner-box{
            width: 100%;
// height: 245px;
background-image: url("../../assets/dashboardBottom.svg");


background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 10px;
margin-top: 48px;
padding: 11px 15px 22px 14px;
.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
        .date-classroom{
            font-weight: 200;
font-size: 48px;
text-transform:uppercase;
text-align:left;

@media screen and (max-width: 968px) {
    font-size:30px ;
    }



color: #FFFFFF;

        }
        .date-classroom-year{
            font-weight: 300;
            font-size: 24px;
            line-height: 29px;
          
            
            color: #FFFFFF;        }
        .college{
            font-weight: 300;
font-size: 24px;
// border: 1px solid red;


line-height: 29px;
/* identical to box height */



color: #FFFFFF;
@media screen and (max-width: 968px) {
  font-size:18px ;
  }
        }
    }
    .right{
        img{
            width: 60px;
            height: 63px;
            @media screen and (max-width: 968px) {
                width: 30px;
                height: 31px;
              }
        }
    }

}
.bottom{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .bottom-inner{
        display: flex;
        gap: 17px;
        img{
            width: 20px;
            height: 20px;
        }
    }
   

}
        }
       
  
  
  }
  .announcement{
    justify-content: space-between;
  
    margin-top: 40px;
    .announce{

        font-weight: 600;
font-size: 24px;
line-height: 9px;
/* or 37% */

display: flex;
align-items: center;

color: #3B3B3B;
@media screen and (max-width: 968px) {
    font-size:18px;
    }
    }
    img{
width: 16px;
height: 16px;
    }
} 


    .introRow{
       
        display: flex !important;
        align-items: center !important;
    // gap: 10px;
    width: 100%;
    height: 100%;
    gap: 10px;

        img{
// width: 30px !important; 
// // border: 1px solid red;

// height: 100% !important;

        }
        .img-calendar{
            width: 30px !important;
            height: 30px !important;
        }
        span{

        }
    }

    .pause{
        width: 95px;
height: 23px;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: $base_color;

background: #FFE2D9;
border-radius: 33px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
    }



// content boxes

.content-Boxes{
    margin-top: 19px;
    .box{
        background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);

box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 7px;
padding: 14px;
justify-content: space-between;
margin-bottom: 14px;
gap: 5px;
@media screen and (max-width: 968px) {
    flex-direction: column;
    }


        
        .left{
            margin-right: 16px;

            .left{
                width: 36px;
height: 36px;




background: #82CEDD;
border-radius: 6px;
justify-content: center;
font-weight: 500;
font-size: 21px;
line-height: 25px;

color: #FFFFFF;
@media screen and (max-width: 968px) {
    font-size:12px ;
    height: 25px;
    }

            }
            .right{

                .annual{
                    font-weight: 500;
font-size: 13px;
line-height: 16px;
display: block;
/* identical to box height */


color: #3B3B3B;

                }
                .date{
                    font-weight: 500;
font-size: 13px;
line-height: 16px;
/* identical to box height */

color: #C4C4C4;

                }
                
            }
        }

    }
}

.studentScore{
    .announce{

        font-weight: 600;
font-size: 24px;
line-height: 9px;
margin-top: 53px;
/* or 37% */

display: flex;
align-items: center;

color: #3B3B3B;
@media screen and (max-width: 968px) {
    font-size:18px;
    }
}
.view-more-btn{
    color: $base_color;
    justify-content: flex-end;
    margin-top: 12.5px;
    font-weight: 500;
font-size: 13px;
line-height: 16px;
cursor: pointer;
    

}
.scores{
    margin-top: 17px;
    align-items: center;
    // border: 1px solid red;
    
    .score-box{
        position: relative;
        background: linear-gradient(90deg, $base_color 0%, $base_color 20.46%, rgba(239, 91, 44, 0) 193.46%);
border-radius: 2.92813px;
color: white;
margin-top: 22px;
padding: 30px 20px;
font-size: 36px;
    line-height: 45px;

&.score-box-1{
    background: linear-gradient(89.96deg, #FFCA2A 57.6%, rgba(255, 255, 255, 0) 235.19%) !important;

    
}
.id{
    font-weight: 400;
// font-size: 30.0786px;
line-height: 25px;


}
        .intro{
            margin-left: 15px;
            align-items: center;
            gap: 1px;
            .name{
                font-weight: 400;
// font-size: 15.0589px;
line-height: 19px;

            }
            .username{
                font-weight: 400;
// font-size: 5.85625px;
line-height: 7px;

            }
            
        }
        .value{
            font-weight: 400;
// font-size: 7.52947px;
line-height: 88.67%;
align-items: center;
margin-left: 30px;

span{
    font-weight: 400;
// font-size: 15.0589px;
line-height: 88.67%;
margin-left: 5px;
/* or 13px */
}
        }

        .img-student{
height: 100px;
position: absolute;
// top: -23.84px;
right: 8px;
bottom: 0;
        }

    }

}
}

// invite dialog
.dialog-invite{
    .header{
    justify-content: space-between;
    margin-bottom: 37px;
    span{
        font-weight: 700;
font-size: 27px;
line-height: 33px;
/* identical to box height */


color: #3B3B3B;
    }
    img{
        width: 39.95px;
height: 40px;
    }
}
.main{
    .groupItem-invite{
        margin-bottom: 36px;
        label{
            font-weight: 400;
font-size: 23px;
// line-height: 12px;
/* identical to box height, or 51% */

text-transform: capitalize;

color: #3B3B3B;
        }
        input,select{
            width: 100%;
            width: 746px;

            height: 40px;
margin-top: 14px;



border: 1px solid #E1DEDE;
background-color: white;
padding: 5px;


        }
        .upload-btn{
            display: block;
            width: 100%;
            border: 1px solid #E1DEDE;

border-radius: 6.18px;
padding: 13px 0 15px 0;
background: white;
font-weight: 500;
font-size: 14px;
line-height: 12px;
text-align: center;
/* identical to box height, or 84% */

margin-top: 14px;

color: #3B3B3B;
        }
    }
    .btns{
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: flex-end;
    }
}}


#Upload{
    display: none;
}

// calendar class list
.view-more{
  
    margin-left: auto;
}

// table 
.table-Content{

    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  
    .top{
        margin-top: 20px;
        margin-left: 12px;
       justify-content: space-between;
       padding-bottom: 9px;
    
      .heading{
        font-weight: 700;
        font-size: 14px;
        line-height: 9px;
        /* or 64% */
        
    
       
        
        color: $base_color;
      }
        
        .right_Content{
            // width: 101.06px;
    height: 20px;
    position: relative;
    // gap: ;
    margin-right: 23px;
    
    .left{
      
    
    
    input{
        width: 100%;
        overflow: hidden;
        padding-left: 22px;
        border: none;
        &::placeholder{
            font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    
    
    color: rgba(0, 0, 0, 0.34);
    
        }
    }
    img{
        position: absolute;
        left: 0;
        top: 50%;
        width: 15px;
        height: 15px;
        transform: translateY(-50%);
    }}
        }
    }
    .underline{
        .left{
            width: 81px;
            // height: 3px;
            border-bottom:3px solid $base_color;
    
        }
        .right{
            // width: 90%;
            border-bottom:1px solid rgba(0, 0, 0, 0.2);
    
    
        }
    
    }
        }
        .right{
            // border: 1px solid red;
            img{
                width: 36px;
                height: 29px;
    
            }
        }


        .calendarTableContent{
            border: none ;
            box-shadow: none;
            .top{
                // border: 1px solid red;
            }
            .right_Content{
                .left{
                    // border: 1px solid red;
                    width: 80%;
                   margin-left: auto;
                   input{
                       padding-left: 28px !important;
                   }
                   img{
                       left: 45px !important;
                   }
                }
            }
            .underline{
               .left{
                   width: 100px;
               }
            }
            
                }


